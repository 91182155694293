<template>
  <div>
    <div class="container">
      <div class="backgroundImage"></div>
      <div class="text-center">
        <div class="subheading">关于我们</div>
        <p style="margin: 20px">We are here, Nothing to fear</p>
        <hr class="full" />
      </div>
      <div class="center">
        <div style="width: 700px">
          广州顺风耳网络科技有限公司是一家专注于家装行业的O2O解决方案服务商，致力于成为家装行业线上整合营销的最佳合作伙伴。我们以优秀的营销理念为基础，成熟的运营能力为内驱引擎，通过全渠道整合、高效引流和构建多维立体的营销体系，为企业打造新零售时代下的全链路营销闭环。我们的目标是帮助企业实现线上线下的高度融合，提升品牌知名度和客户满意度，同时降低运营成本，实现业绩持续增长。我们相信，通过我们的专业服务和卓越执行力，我们可以与您携手共创更加美好的未来。
        </div>
      </div>
      <div class="text-center">
        <div class="subheading">我们的服务</div>
        <p style="margin: 20px">We are lucky to service people like you</p>
        <hr class="full" />
      </div>
      <div class="mainBody">
        <div class="content">
          <img src="../assets/diannao.png" />
          <div class="headline">渠道推广</div>
          <div style="width: 650px" class="center">
            顺风耳线上营销团队的核心成员来自国内一线互联网企业和定制家居上市品牌，具有多渠道系统化的家装运营推广管理优势。我们擅长短视频推广、信息流推广、搜索推广、电商平台运营等推广方式，并且针对家装行业的获客难题，提供一站式、实用有效的获客运营方案。
          </div>
        </div>
        <div class="content">
          <img src="../assets/yingyong.png" />
          <div class="headline">电销运营</div>
          <div style="width: 650px" class="center">
            我们拥有家装行业顶级的电销管理运营团队，团队成员的电销经验最高可达13年。我们拥有5名电销主管经理，他们的管理经验在4-7年不等。我们擅长各种新项目的上线落地执行，并且能够培养和孵化电销新人。同时，我们还能准确提供前端城市投放的协同建议，共同调整投放城市的结构，以提高效率。
          </div>
        </div>
        <div class="content">
          <img src="../assets/deng.png" />
          <div class="headline">CRM系统服务</div>
          <div style="width: 650px" class="center">
            我们独立开发定制系统，拥有搭建推广、电销和门店系统的能力。根据需求，我们能够定制管理驱动工具、个人驱动模型和资源分配模型等，以提升团队和个人的运作指标，有机驱动团队之间的协同效率。我们的系统能够为流量变现提供强有力的工具，实现系统化、数字化管理。
          </div>
        </div>
        <div class="content">
          <img src="../assets/shu.png" />
          <div class="headline">咨询服务</div>
          <div style="width: 650px" class="center">
            我们提供专门为家居行业定制咨询服务，从前端流量获取到后端变现全流程把控，致力于提升家装行业的整体效率和业绩。我们的专业团队具有丰富的行业经验和知识，帮助您解决在运营过程中遇到的各种问题。我们关注市场趋势和消费者需求，为您带来更多的商机和竞争优势。
          </div>
        </div>
      </div>
    </div>
    <div class="base">
      <div class="detail" style="padding-top: 20px">联系我们：</div>
      <div class="detail">Mail：charleswork@foxmail.com</div>
      <div class="detail" style="margin-bottom: 6px">
        地址：广州市天河区东圃二马路67号10、11、12房部位：12-206房
      </div>
      <div class="ICP">
        <img src="../assets/ioc.png" />
        <a href="https://beian.miit.gov.cn" class="ICPstyle">
          粤ICP备2023071349号
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.container {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
}

.backgroundImage {
  width: 100%;
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-image: url(../assets/logo.png);
}

.subheading {
  font-size: 49px;
  padding-top: 58px;
}

.text-center {
  padding-top: 40px;
}

.full {
  width: 6%;
  margin: auto;
}

.center {
  margin-top: 20px;
  color: #6d7c7c;
  font-size: 16px;
  line-height: 1.6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainBody {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.content {
  width: 650px;
  margin-top: 40px;
}

.base {
  width: 100%;
  height: 120px;
  margin-top: 50px;
  background-color: black;
}

.headline {
  padding: 10px;
  font-size: 30px;
}

.detail {
  font-size: 12px;
  margin-left: 7%;
  padding-top: 6px;
  color: #fff;
}

.ICPstyle {
  font-size: 12px;
  margin-left: 8px;
  color: #fff;
  height: 26px;
  line-height: 26px;
}

.ICP {
  margin-left: 7%;
  display: flex;
  align-items: center;
}
</style>
